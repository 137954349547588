import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { getKitchensQuery } from './graphql'
import { LocationButton } from './LocationButton'

import {
  Kitchen,
  Query,
  QueryKitchensArgs,
  QueryMode,
  SortOrder,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader, SearchIcon } from '../../../components'
import { NewLayout, Typography } from '../../../components/newUi'
import { TextField } from '../../../components/newUi/TextField'
import { useDebounce } from '../../../hooks'
import { useToastQueue } from '../../../hooks/useToastQueue'

export function Locations() {
  useToastQueue()
  const { selectedKitchen } = useKitchen()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { data, previousData, loading } = useQuery<
    { kitchens: Query['kitchens'] },
    QueryKitchensArgs
  >(getKitchensQuery, {
    variables: {
      orderBy: [{ name: SortOrder.Asc }],
      take: 250,
      where: {
        name: {
          contains: debouncedSearch,
          mode: QueryMode.Insensitive,
        },
      },
    },
  })

  const [kitchens, setKitchens] = useState<Kitchen[]>([])

  useEffect(
    () => setKitchens(data?.kitchens ?? previousData?.kitchens ?? []),
    [data],
  )

  return (
    <>
      <NewLayout
        subtitle="Settings"
        title="Locations"
        hideBack={!selectedKitchen}
      />

      <div style={{ padding: '0.75rem' }}>
        <TextField
          placeholder="Search by location"
          onChange={(value) => setSearch((value ?? '').toString())}
          endAdornment={
            loading && kitchens.length ? <Loader size={20} /> : <SearchIcon />
          }
        />

        {loading && !kitchens.length ? <Loader /> : <></>}

        {kitchens.map((kitchen) => (
          <LocationButton key={kitchen.id} kitchen={kitchen} />
        ))}

        <div
          style={{
            paddingBottom: '2rem',
            paddingTop: '2rem',
            textAlign: 'center',
          }}
        >
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            Want to add a new location?
          </Typography>

          <Typography variant="subtitle1" className="mt-2">
            Contact{' '}
            <a
              href="mailto:letscook@getjelly.co.uk"
              className="text-primary-900"
            >
              letscook@getjelly.co.uk
            </a>
          </Typography>
        </div>
      </div>
    </>
  )
}
