import { ApolloClient, from, InMemoryCache, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException } from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { uniq } from 'ramda'

import { getToken } from 'api/token'
import { API } from 'app'
import { store } from 'store'
import { clearToken, setToken } from 'store/auth'

const retryLink = new RetryLink()

const logError = (error: string) => {
  try {
    captureException(new Error(error))
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('errorLink: error calling Sentry.captureException', ex)
  }
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      logError(JSON.stringify(error))
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations,
        )}, Path: ${JSON.stringify(error.path)}`,
      )
    })
  }
  return forward(operation)
})

const authorizationLink = setContext((_req, { headers }) => {
  const result = getToken()
  const authHeader = result ? { authorization: result.token } : {}

  return {
    headers: {
      ...headers,
      ...authHeader,
    } as Headers,
  }
})

export const getRefreshToken: () => Promise<Response> = async () => {
  if (!API) {
    throw new Error('API is not defined')
  }
  return await fetch(API, {
    body: JSON.stringify({
      query: `
          mutation {
            refresh {
              token
            }
          }
        `,
    }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
}

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'refresh',
  fetchAccessToken: getRefreshToken,
  handleError: (error) => {
    logError(JSON.stringify(error))
    store.dispatch(clearToken())
  },
  handleFetch: (refresh: { token: string }) => {
    store.dispatch(setToken(refresh.token))
  },
  isTokenValidOrUndefined: () => {
    const token = getToken()
    return !token || token.data.exp * 1000 > Date.now()
  },
})

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: API,
})

const canaryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const canaryHeader = context.response.headers.get('X-Canary')
    const betaUrl = import.meta.env.VITE_REACT_APP_BETA_URL

    if (betaUrl) {
      const baseUrl = betaUrl.replace('beta.', '')

      if (canaryHeader === 'true') {
        window.location.href = `${betaUrl}${window.location.pathname}`
      } else if (!canaryHeader && window.location.href.startsWith(betaUrl)) {
        window.location.href = `${baseUrl}${window.location.pathname}`
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Beta URL is not defined in environment variables.')
    }

    return response
  })
})

function merge(
  existing = { edges: [], nodes: [] },
  incoming = { edges: [], nodes: [] },
) {
  return {
    ...incoming,
    edges: uniq([...(existing.edges ?? []), ...(incoming.edges ?? [])]),
    nodes: uniq([...(existing.nodes ?? []), ...(incoming.nodes ?? [])]),
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allInvoiceList: {
          keyArgs: ['where', 'searchTerm', 'kitchenCursor', 'supplierId'],
          merge,
        },
        approvedInvoiceList: {
          keyArgs: ['where', 'searchTerm', 'kitchenCursor', 'supplierId'],
          merge,
        },
        fuzzySearchProductList: {
          keyArgs: [
            'where',
            'orderBy',
            'searchTerm',
            'supplierCursor',
            'kitchenCursor',
          ],
          merge,
        },
        fuzzySearchProducts: {
          keyArgs: ['kitchenId', 'supplierId', 'searchTerm'],
          merge(
            existing = {
              products: [],
            },
            incoming,
          ) {
            return {
              ...existing,
              ...incoming,
              products: uniq([...existing.products, ...incoming.products]),
            }
          },
        },
        ingredientList: {
          keyArgs: ['kitchenId', 'where', 'orderBy', 'search'],
          merge,
        },
        ingredients: {
          keyArgs: ['where', 'orderBy', 'skip', 'take'],
          merge(existing = [], incoming) {
            return uniq([...existing, ...incoming])
          },
        },
        kitchenTodos: {
          keyArgs: ['kitchenId', 'completed'],
          merge(existing = [], incoming) {
            return uniq([...existing, ...incoming])
          },
        },
        menuList: {
          keyArgs: ['where', 'orderBy'],
          merge,
        },
        needsAttentionInvoiceList: {
          keyArgs: ['where', 'searchTerm', 'kitchenCursor', 'supplierId'],
          merge,
        },
        pendingInvoiceListGrouped: {
          keyArgs: ['where', 'searchTerm', 'kitchenCursor'],
          merge,
        },
        processingInvoiceList: {
          keyArgs: ['kitchenCursor'],
          merge,
        },
        purchaseOrderList: {
          keyArgs: ['where', 'orderBy'],
          merge,
        },
        purchaseOrders: {
          keyArgs: ['where', 'orderBy'],
          merge(existing = [], incoming) {
            return uniq([...existing, ...incoming])
          },
        },
        recipeList: {
          keyArgs: ['where', 'orderBy'],
          merge,
        },
        suppliers: {
          keyArgs: ['where', 'orderBy', 'fuzzySearch'],
          merge(existing = [], incoming) {
            return uniq([...existing, ...incoming])
          },
        },
      },
    },
  },
})

const link = from([
  refreshLink,
  authorizationLink,
  // canaryLink,
  errorLink,
  retryLink,
  httpLink,
])

export const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  link,
})
