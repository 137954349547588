import { IconChevronRight } from '@tabler/icons-react'
import { ReactNode } from 'react'

import { Loader } from 'components'
import { Typography } from 'components/newUi'

type Props = {
  onClick: () => void
  text: string
  subtext?: string
  loading?: boolean
  startAdornment?: ReactNode
  boldTitle?: boolean
}

export function NavItem({
  onClick,
  text,
  subtext,
  loading,
  startAdornment,
  boldTitle,
}: Props) {
  return (
    <button
      onClick={onClick}
      className="bg-transparent border-0 block p-0 text-left w-full"
    >
      <div className="px-3 py-4 rounded shadow flex justify-between items-center bg-white">
        {loading ? (
          <div className="flex justify-center w-full">
            <Loader />
          </div>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div className="space-x-2 flex">
              {startAdornment}

              <div className="flex items-center">
                <div className="space-y-1">
                  <Typography
                    variant="body1"
                    className="!text-primary-900"
                    style={{ fontWeight: boldTitle ? 500 : 400 }}
                  >
                    {text}
                  </Typography>

                  {subtext && (
                    <Typography variant="body2" className="!text-primary-900">
                      {subtext}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <IconChevronRight className="text-primary-400" />
      </div>
    </button>
  )
}
