import { useLocation, Outlet, useNavigate } from 'react-router-dom'

import { NewLayout, ScrollMenu, ScrollMenuItem } from 'components/newUi'
import { routes } from 'routes/Paths'

export const StockLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <>
      <NewLayout title="Stock" onBack={() => navigate(-1)} />

      <ScrollMenu>
        <ScrollMenuItem
          text="Stocktake"
          selected={pathname.includes(`${routes.Stock}${routes.Take}`)}
          route={`${routes.Stock}${routes.Take}`}
        />

        <ScrollMenuItem
          text="Your Products"
          selected={pathname.includes(`${routes.Stock}${routes.Product}`)}
          route={`${routes.Stock}${routes.Product}`}
        />
      </ScrollMenu>
      <Outlet />
    </>
  )
}
