import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'store/store'

export type TempState = {
  onboardStep: number[]
}

export const TEMP_INITIAL_STATE: TempState = {
  onboardStep: [1],
}

export const TEMP_SLICE = createSlice({
  initialState: TEMP_INITIAL_STATE,
  name: 'temp',
  reducers: {
    setOnboardStep(state, action: PayloadAction<number[]>) {
      state.onboardStep = action.payload
    },
  },
})

export const { reducer: tempReducer } = TEMP_SLICE

export const { setOnboardStep } = TEMP_SLICE.actions

export const selectOnboardStep = (state: RootState) => state.temp.onboardStep
