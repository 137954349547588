import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { CircularProgress } from 'components'

export const Auth = () => {
  return (
    <div className="min-h-full bg-primary-900 p-4 flex justify-center items-center">
      <Suspense fallback={<CircularProgress color="secondary" size={60} />}>
        <div className="max-w-96 w-full">
          <Outlet />
        </div>
      </Suspense>
    </div>
  )
}
