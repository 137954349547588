import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

interface Props {
  path: string
  params?: { replace?: boolean; state?: Record<string, unknown> | null } // TODO replace with correct type from react-router-dom when released
  appendReturnRoute?: boolean
}

export const Redirect = ({ path, params, appendReturnRoute }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    navigate(
      appendReturnRoute ? `${path}?redirect=${location.pathname}` : path,
      {
        replace: true,
        ...params,
      },
    )
  }, [navigate, params, path])
  return null
}
