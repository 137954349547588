import { useState, useEffect, useCallback } from 'react'

import { api } from 'api/rest'

type TClientConfig = {
  scope: string
  clientId: string
  authUrl: string
  tokenUrl: string
  redirectUri: string
  secretArn: string
  state: string
}

export type TIntegration = {
  id: number
  name: string
  clientConfig: TClientConfig
  createdAt: string
  updatedAt: string
  baseUrl: string
  constructedAuthUrl: string
  connected: boolean
  disconnect: () => void
}

const useIntegrations = (kitchenId: number) => {
  const [integrations, setIntegrations] = useState<TIntegration[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const disconnectIntegration = useCallback(async (id: number) => {
    try {
      await api.post(`/rest/integrations/${id}/disconnect`, { kitchenId })
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((integration) =>
          integration.id === id
            ? { ...integration, connected: false }
            : integration,
        ),
      )
    } catch (err) {
      setError('Failed to disconnect integration.')
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }, [])

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await api.get<TIntegration[]>(
          `/rest/integrations?kitchenId=${kitchenId}`,
        )
        setIntegrations(
          response.data.map(({ clientConfig, ...integration }) => ({
            ...integration,
            clientConfig,
            constructedAuthUrl: `${clientConfig.authUrl}?client_id=${clientConfig.clientId}&scope=${clientConfig.scope}&redirect_uri=${clientConfig.redirectUri}&response_type=code&state=${clientConfig.state}`,
            disconnect: () => disconnectIntegration(integration.id),
          })) as TIntegration[],
        )
      } catch (err) {
        setError('Failed to fetch integrations.')
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchIntegrations()
  }, [disconnectIntegration])

  return { error, integrations, loading }
}

export default useIntegrations
