import { Route, Routes } from 'react-router-dom'

import { IOSInstallBanner } from 'components/newUi/IOSInstallBanner'
import { Auth as AuthLayout } from 'layouts'
import { ForgotPassword } from 'screens/ForgotPassword'
import { NewPassword } from 'screens/NewPassword'
import { ResetPassword } from 'screens/ResetPassword'
import { SignIn } from 'screens/SignIn'
import { VerifyEmail } from 'screens/VerifyEmail'

import { routes } from './Paths'
import { Redirect } from './Redirect'

import { VITE_REACT_APP_DEMO_JOIN_CODE } from '../app'
import { AcceptInvite } from '../screens/AcceptInvite'
import { SignUp } from '../screens/SignUp'
import { VerifyPhone } from '../screens/VerifyPhone'
import { Welcome } from '../screens/Welcome'

export function PublicRoutes() {
  const joinDemoRedirect = (
    <Redirect path={`${routes.Join}/${VITE_REACT_APP_DEMO_JOIN_CODE}`} />
  )

  return (
    <Routes>
      <Route path={routes.Home} element={joinDemoRedirect} />

      <Route
        path={routes.Home}
        element={
          <>
            <AuthLayout />
            <IOSInstallBanner />
          </>
        }
      >
        <Route path={routes.Welcome} element={<Welcome />} />
        <Route path={routes.SignIn} element={<SignIn />} />
        <Route path={routes.SignUp} element={<SignUp />} />
        <Route path={routes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={routes.ResetPassword} element={<ResetPassword />} />
        <Route path={routes.NewPassword} element={<NewPassword />} />
        <Route path={routes.VerifyPhone} element={<VerifyPhone />} />
        <Route path={`${routes.Join}/:code`} element={<AcceptInvite />} />

        <Route path={routes.VerifyEmail} element={<VerifyEmail />} />

        {/* Old Routes */}
        <Route path={routes.SignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.VerifySignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.CompleteSignUpPhone} element={joinDemoRedirect} />
        <Route path={routes.Join + routes.Verify} element={joinDemoRedirect} />

        <Route
          path={routes.Join + routes.CreateProfile}
          element={joinDemoRedirect}
        />
      </Route>

      <Route
        path="*"
        element={<Redirect path={routes.SignIn} appendReturnRoute />}
      />
    </Routes>
  )
}
