import { useQuery } from '@apollo/client'
import {
  InvoicesOnePlaceEmptyState,
  InvoicesOnePlaceNotPaidState,
} from '@getjelly/jelly-ui'
import { isEmpty } from 'ramda'
import { CSSProperties, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import {
  PendingInvoiceBaseFragment,
  PendingInvoice,
  PendingInvoiceGroupedConnection,
  QueryAllInvoiceListArgs,
  Invoice,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { SearchIcon, CloseIcon, SupplierSelect } from 'components'
import { VirtualizedSectionList, NewTextField } from 'components/newUi'
import { useDebounce, useIsPaidKitchen } from 'hooks'
import PendingInvoiceRowItem from 'screens/Spending/Invoices/components/RowItems/PendingInvoiceRowItem'
import { allInvoiceList } from 'screens/Spending/Invoices/graphql/allInvoiceList'

import { InvoiceRowItem } from './components/RowItems/InvoiceRowItem'
import { groupInvoices } from './groupInvoices'

import { ApprovedInvoiceSkeleton } from '../../../components/Skeleton/Invoice/ApprovedInvoiceSkeleton'
import { GroupedInvoicesSkeleton } from '../../../components/Skeleton/Invoice/GroupedInvoicesSkeleton'
import { selectCounts } from '../../../store/kitchen'
import { selectSupplier } from '../../../store/supplier'

type InvoiceGroup = { label: string; items: PendingInvoiceBaseFragment[] }

export function AllInvoicesScreen() {
  const { selectedKitchen } = useKitchen(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''
  const isFeatureAvailable = useIsPaidKitchen()

  const selectedSupplier = useSelector(selectSupplier())

  const counts = useSelector(selectCounts())

  const debouncedSearch = useDebounce(search, 500)

  const { data, loading, fetchMore, client } = useQuery<
    { allInvoiceList: PendingInvoiceGroupedConnection },
    QueryAllInvoiceListArgs
  >(allInvoiceList, {
    fetchPolicy: 'network-only',
    skip: !selectedKitchen,
    variables: {
      kitchenCursor: selectedKitchen?._cursor ?? '',
      searchTerm: debouncedSearch,
      skip: 0,
      supplierId: selectedSupplier?.id ?? 0,
      take: 20,
    },
  })

  useEffect(() => {
    client.cache.evict({ fieldName: 'allInvoiceList' })
  }, [])

  const sortedData: InvoiceGroup[] = useMemo(() => {
    if (!data?.allInvoiceList) {
      return []
    }

    return groupInvoices(data.allInvoiceList)
  }, [data])

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (
    !debouncedSearch &&
    counts.invoiceCount === 0 &&
    (data?.allInvoiceList.count ?? 0) === 0
  ) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <InvoicesOnePlaceEmptyState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <SupplierSelect title="Spending" />
      </div>

      <div className="p-4 space-y-3">
        <div className="flex items-center !space-x-4">
          <NewTextField
            className="!p-0 w-full"
            inputProps={{
              'data-hj-allow': '',
              style: { borderRadius: 25 },
            }}
            placeholder="Search by invoice number"
            value={search}
            onChange={(value) =>
              setSearchParams(
                { query: value?.toString() ?? '' },
                { replace: true },
              )
            }
            endAdornment={
              isEmpty(search) ? (
                <SearchIcon />
              ) : (
                <CloseIcon
                  onClick={() =>
                    setSearchParams({ query: '' }, { replace: true })
                  }
                />
              )
            }
          />
        </div>
      </div>

      {!data && (
        <GroupedInvoicesSkeleton
          invoiceSkeleton={<ApprovedInvoiceSkeleton />}
        />
      )}

      {data && (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <VirtualizedSectionList
            pageInfo={data.allInvoiceList.pageInfo}
            ROW_HEIGHT={({ invoice, status }) => {
              if (status === 'pending' || status === 'processing') {
                return 95
              }

              if (!invoice) return 95

              return !(invoice as Invoice).approved && invoice.invoiceTotal
                ? 113
                : 95
            }}
            Item={(props: {
              data: InvoiceGroup
              index: number
              isVisible: boolean
              style: CSSProperties
            }) => {
              // add handleInvoiceSelect handler to Item
              const pendingInvoice = props.data.items[
                props.index
              ] as PendingInvoice
              return pendingInvoice.invoice ? (
                <InvoiceRowItem
                  pendingInvoiceId={pendingInvoice.id}
                  notes={pendingInvoice.notes}
                  invoice={pendingInvoice.invoice}
                  {...props}
                />
              ) : (
                <PendingInvoiceRowItem invoice={pendingInvoice} {...props} />
              )
            }}
            data={sortedData}
            loading={loading}
            fetchMore={() => {
              if (!loading) {
                fetchMore({
                  variables: {
                    skip: data?.allInvoiceList.nodes?.length,
                    take: 20,
                  },
                })
              }
            }}
          />
        </div>
      )}
    </>
  )
}
