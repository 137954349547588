import * as React from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
  children?: React.ReactNode
}

export const ScrollMenu: React.FunctionComponent<IProps> = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(244, 245, 246, 1)',
        flexDirection: 'row',
        maxHeight: 44, // weird
        minHeight: 44, // issue
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
      }}
    >
      <div
        style={{
          borderBottomColor: 'rgba(219, 222, 226, 1)',
          borderBottomStyle: 'solid',
          borderBottomWidth: 3,
          display: 'flex',
          height: '100%',
          minWidth: 'max-content',
          // width: 'max-content',
        }}
      >
        {children}
      </div>
    </div>
  )
}
export const ScrollMenuItem = ({
  text,
  selected,
  route,
}: {
  text: string
  selected: boolean
  route: string
}) => {
  const navigate = useNavigate()
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={() => navigate(route, { replace: true })}
      style={{
        backgroundColor: 'transparent',
        color: selected ? undefined : 'rgba(121, 131, 146, 1)',
        cursor: 'pointer',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 500,
        paddingBottom: 15,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        position: 'relative',
        whiteSpace: 'nowrap',
      }}
    >
      {text}
      {selected && (
        <div
          style={{
            backgroundColor: '#1F304A',
            bottom: -3,
            height: 3,
            position: 'absolute',
            right: 0,
            // transform: 'translateY(50%)',
            // borderBottomColor: selected ? '#F5D94F' : 'rgba(219, 222, 226, 1)',
            // borderBottomStyle: selected ? 'solid' : undefined,
            // borderBottomWidth: 3,
            width: '100%',
          }}
        ></div>
      )}
    </div>
  )
}
