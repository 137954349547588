import { useMutation } from '@apollo/client'
import { InsightsListGroup, InsightsListItem } from '@getjelly/jelly-ui'
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  parse,
} from 'date-fns'
import { useMemo, useState } from 'react'

import { createOrUpdateOneSale } from './graphql'
import { CurrentSales, UpdateSalesModal } from './UpdateSalesModal'

import {
  FlashSales,
  Mutation,
  MutationCreateOrUpdateOneSaleArgs,
  PeriodEnum,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { errorToast } from '../../../components/toasts'
import { toUTCStartOfDay } from '../../../utils'

type Props = {
  periodEnum: PeriodEnum
  startDate: Date
  endDate: Date
  flashSales: FlashSales[]
  goToMonth: (month: Date) => void
}

export function FoodFlashSalesList({
  periodEnum,
  startDate,
  endDate,
  flashSales,
  goToMonth,
}: Props) {
  const { selectedKitchen } = useKitchen()

  const sales = useMemo(() => {
    const days = eachDayOfInterval({ end: endDate, start: startDate })

    return days.map((d) => {
      return {
        date: d,
        sales: flashSales.find((s) =>
          isSameDay(parse(s.date, 'yyyy-MM-dd', new Date()), d),
        )?.total,
      }
    })
  }, [flashSales, endDate, startDate])

  const monthlySales = useMemo(() => {
    const months = eachMonthOfInterval({ end: endDate, start: startDate })
    return months.map((m) => {
      return {
        date: m,
        sales: flashSales.reduce((acc, s) => {
          if (isSameMonth(parse(s.date, 'yyyy-MM-dd', new Date()), m)) {
            acc += s.total
          }

          return acc
        }, 0),
      }
    })
  }, [flashSales, startDate, endDate])

  const [showModal, setShowModal] = useState(false)
  const [currentSales, setCurrentSales] = useState<CurrentSales>()

  function openModal(sales: CurrentSales) {
    setCurrentSales(sales)
    setShowModal(true)
  }

  const [submitSale] = useMutation<
    {
      createOrUpdateOneSale: Mutation['createOrUpdateOneSale']
    },
    MutationCreateOrUpdateOneSaleArgs
  >(createOrUpdateOneSale, {
    awaitRefetchQueries: true,
    refetchQueries: ['flashInsights'],
  })

  const readonly = useMemo(
    () => !selectedKitchen?.userPermissions.includes('update-sale'),
    [selectedKitchen],
  )

  return (
    <>
      <InsightsListGroup title="By Sales">
        {periodEnum !== 'quarter'
          ? sales.map((d) => {
              return (
                <InsightsListItem
                  readonly={readonly}
                  key={d.date.toString()}
                  title={format(d.date, 'd MMM')}
                  subtitle={format(d.date, 'EEEE')}
                  data={
                    d.sales === undefined
                      ? readonly
                        ? ''
                        : 'Add'
                      : d.sales.toLocaleString(undefined, {
                          currency: 'GBP',
                          style: 'currency',
                        })
                  }
                  accent="success"
                  onClick={() => openModal(d)}
                />
              )
            })
          : monthlySales.map((m) => (
              <InsightsListItem
                readonly={readonly}
                key={m.date.toString()}
                title={format(m.date, 'MMMM')}
                subtitle={`${format(m.date, 'd')} - ${format(
                  endOfMonth(m.date),
                  'd MMM',
                )}`}
                data={m.sales.toLocaleString(undefined, {
                  currency: 'GBP',
                  style: 'currency',
                })}
                accent="success"
                onClick={() => goToMonth(m.date)}
              />
            ))}
      </InsightsListGroup>

      {currentSales && (
        <UpdateSalesModal
          currentSales={currentSales}
          open={showModal}
          onClose={() => {
            setShowModal(false)
            setCurrentSales(undefined)
          }}
          saveSales={async (date: Date, sales: number | undefined) => {
            if (sales === currentSales.sales) {
              return setShowModal(false)
            }

            if ((sales || 0) < 0) {
              errorToast('Sales must not be less than than 0')
              return
            }

            try {
              await submitSale({
                variables: {
                  data: {
                    id: 0,
                    kitchenId: selectedKitchen?.id || 0,
                    sales: sales || 0,
                    salesDate: toUTCStartOfDay(date),
                  },
                },
              })
            } catch (error) {
              errorToast('Unable to update sales.')
            } finally {
              setShowModal(false)
            }
          }}
        />
      )}
    </>
  )
}
