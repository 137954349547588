import { useEffect } from 'react'

import { VITE_REACT_APP_HOTJAR } from 'app'

import { useKitchen } from '../../app/contexts/SelectedKitchen'

export const Trackers = () => {
  const { selectedKitchen } = useKitchen(false)

  const kitchenId = selectedKitchen?.id

  useEffect(() => {
    if (kitchenId && VITE_REACT_APP_HOTJAR && window.hj) {
      window.hj('identify', kitchenId, {
        userId: kitchenId,
      })
    }
  }, [kitchenId])

  return <></>
}
