import { useQuery } from '@apollo/client'
import {
  DashboardAlert,
  DashboardNumberCard,
  DashboardSection,
} from '@getjelly/jelly-ui'
import { IconTrendingUp } from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FinanceNavigation } from './FinanceNavigation'
import { dashboardFinanceQuery } from './graphql'

import { PeriodEnum, Query, QueryDashboardFinanceArgs } from '../../api'
import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { Loader } from '../../components'
import { CalendarPeriodSelector } from '../../components/Insights/CalendarPeriodSelector'
import { NewLayout } from '../../components/newUi'
import { routes } from '../../routes/Paths'

export function Finance() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen(true)
  const [periodEnum, setPeriodEnum] = useState<PeriodEnum>(PeriodEnum.Week)

  const { data, loading } = useQuery<
    {
      dashboardFinance: Query['dashboardFinance']
    },
    QueryDashboardFinanceArgs
  >(dashboardFinanceQuery, {
    fetchPolicy: 'no-cache',
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id ?? 0,
      period: periodEnum,
    },
  })

  // const { gpPct, spendPct } = useMemo(() => {
  //   if (!data) {
  //     return { gpPct: 0, spendPct: 0 }
  //   }
  //
  //   if (data.dashboardFinance.totalSales === 0) {
  //     return { gpPct: 0, spendPct: 0 }
  //   }
  //
  //   const spendPercent = Math.round(
  //     (data.dashboardFinance.totalSpend / data.dashboardFinance.totalSales) *
  //       100,
  //   )
  //
  //   return { gpPct: 100 - spendPercent, spendPct: spendPercent }
  // }, [data])

  const missingSalesDays = useMemo(() => {
    if (!data) return 0
    return data.dashboardFinance.daysOfMissingSales
  }, [data])

  if (!data && loading) {
    return (
      <>
        <NewLayout title="Finance" hideBack={true} />
        <FinanceNavigation />

        <Loader />
      </>
    )
  }

  return (
    <>
      <NewLayout title="Finance" hideBack={true} />
      <FinanceNavigation />

      <div className="w-full flex justify-center">
        <div className="w-full max-w-[32rem] pb-6">
          <CalendarPeriodSelector
            value={periodEnum}
            onChange={setPeriodEnum}
            className="pt-4"
          />

          <DashboardSection title="Invoices">
            <DashboardNumberCard
              title="Needs Attention"
              data={
                data?.dashboardFinance.totalInvoicesNeedAttention.toString() ??
                ''
              }
              accent="error"
              onClick={() =>
                navigate(
                  `${routes.Spending}/${routes.Invoice}/${routes.History}`,
                )
              }
            />
          </DashboardSection>

          <DashboardSection title="Insights">
            <DashboardAlert
              title="Large Price Changes"
              subtitle="See all the price changes from your suppliers"
              onClick={() => navigate(routes.Spending + routes.Changes)}
              icon={IconTrendingUp}
            />

            {missingSalesDays > 0 && (
              <DashboardAlert
                title={
                  <span>
                    Missing{' '}
                    <span className="text-error-400">{missingSalesDays}</span>{' '}
                    days of sales data
                  </span>
                }
                subtitle="Figures below might not be accurate, please update your sales data."
                onClick={() => navigate(routes.Spending + routes.FoodFlash)}
              />
            )}

            {/*<DashboardPieChart*/}
            {/*  title="Sales (excl. VAT)"*/}
            {/*  data={formatMoney(data?.dashboardFinance.totalSales ?? 0)}*/}
            {/*  dataPoint1={{ text: 'Spend', value: spendPct }}*/}
            {/*  dataPoint2={{ text: 'GP', value: gpPct }}*/}
            {/*/>*/}
          </DashboardSection>
        </div>
      </div>
    </>
  )
}
