import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Select, { components } from 'react-select'

import {
  GooglePlaceDetailsApi,
  Query,
  QueryPlaceDetailsArgs,
  QueryPlacesAutoCompleteListArgs,
} from 'api'
import { Button, Icon, NewLayout, TextButton } from 'components/newUi'
import { Form } from 'screens/Settings/Addresses/Form'
import { useTheme } from 'styles/newUi'

import { placesAutoCompleteListQuery, placesDetailsQuery } from './graphql'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'

export const AddAddress = () => {
  const { selectedKitchen } = useKitchen(true)
  const { theme } = useTheme()
  const [manual, setManual] = useState(false)
  const [sendForm, setSendForm] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [placesList, setPlacesList] = useState<
    { label: string; value: string }[]
  >([])
  const [placeId, setPlaceId] = useState<string>('')
  const [place, setPlace] = useState<GooglePlaceDetailsApi>()

  const { data: places } = useQuery<
    {
      placesAutoCompleteList: Query['placesAutoCompleteList']
    },
    QueryPlacesAutoCompleteListArgs
  >(placesAutoCompleteListQuery, {
    variables: {
      where: {
        search: search ?? '',
      },
    },
  })

  const { data: placeDetails } = useQuery<
    {
      placeDetails: Query['placeDetails']
    },
    QueryPlaceDetailsArgs
  >(placesDetailsQuery, {
    variables: {
      where: {
        placeId,
      },
    },
  })

  const dropDownStyles = {
    container: () => ({
      width: '100%',
    }),
    //control = box before clicked
    control: (styles: any, { isFocused }: any) => ({
      ...styles,
      borderRadius: '50px',
      boxShadow: isFocused
        ? `0 0 0 2px ${theme.palette.secondary[100].toHexString()}`
        : 'none',
      color: theme.palette.primary[100].toHexString(),
      cursor: 'pointer',
      width: 'calc(100% + 4px)',
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    menu: () => ({
      backgroundColor: 'white',
      marginTop: 0,
    }),
  }

  useEffect(() => {
    if (
      places?.placesAutoCompleteList &&
      places?.placesAutoCompleteList?.length > 0
    ) {
      const list = places.placesAutoCompleteList?.map((place) => {
        return {
          label: place.description,
          value: place.placeId,
        }
      })

      setPlacesList(list)
    }
  }, [places])

  useEffect(() => {
    if (placeDetails?.placeDetails) {
      setPlace(placeDetails?.placeDetails)
      setManual(true)
    }
  }, [placeDetails])

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon iconName="search" />
      </components.DropdownIndicator>
    )
  }

  return (
    <>
      <NewLayout
        title="Add address"
        subtitle="Settings"
        bottomBackgroundColor="white"
        bottomContent={
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
          <div>
            {manual ? (
              <div
                style={{
                  alignItems: 'center',
                  borderTop: `1px solid ${theme.palette.primary[10].toHexString()}`,
                  cursor: 'pointer',
                  display: 'grid',
                  padding: theme.spacing(2),
                  width: '100%',
                }}
              >
                <Button
                  text="Add Address"
                  onClick={() => setSendForm(true)}
                ></Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
      <div>
        {manual ? (
          <Form
            create={true}
            sendForm={sendForm}
            kitchenId={selectedKitchen?.id ?? 0}
            place={place}
          />
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '94vh',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: theme.spacing(2),
                width: '100%',
              }}
            >
              <Select
                styles={dropDownStyles}
                options={placesList ?? []}
                inputValue={search}
                placeholder="Search for your kitchen's address"
                isSearchable={true}
                openMenuOnClick={false}
                onInputChange={(value) => {
                  if (value) setSearch(value)
                  else setSearch('')
                }}
                onChange={(value) => {
                  if (value) setPlaceId(value?.value)
                }}
                components={{ DropdownIndicator }}
              ></Select>
              <TextButton
                style={{
                  color: theme.palette.secondary[100].toHexString(),
                  marginTop: 20,
                }}
                onClick={() => {
                  setManual(true)
                }}
              >
                Enter Address
                <Icon iconName="arrowForward" />
              </TextButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
