import { useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewLayout, ScrollMenu, ScrollMenuItem } from 'components/newUi'
import { routes } from 'routes/Paths'
import { ExportButton } from 'screens/Spending/Invoices/components/ExportButton'
import { RulesButton } from 'screens/Spending/Invoices/components/RulesButton'
import { AddInvoiceModal } from 'screens/Spending/Invoices/modals/AddInvoiceModal'

import { invoiceOverviewQuery } from './graphql'

import { Query, QueryInvoiceOverviewArgs } from '../../api'

export function SpendLayout() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen(true)
  const { pathname } = useLocation()
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false)

  const { data, refetch } = useQuery<
    {
      invoiceOverview: Query['invoiceOverview']
    },
    QueryInvoiceOverviewArgs
  >(invoiceOverviewQuery, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id ?? 0,
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, pathname])

  const totals = useMemo(() => {
    if (!data) {
      return { all: 0, approved: 0, needsAttention: 0, processing: 0 }
    }

    return data.invoiceOverview
  }, [data])

  const showExportButton = pathname.includes(
    `${routes.Spending}${routes.Invoice}${routes.Approved}`,
  )

  const showRulesButton = pathname.includes(
    `${routes.Spending}${routes.Invoice}${routes.History}`,
  )

  return (
    <>
      <NewLayout
        title="Invoices"
        onBack={() => navigate(-1)}
        rightContent={
          selectedKitchen?.userPermissions?.includes(
            'create-pending-invoice',
          ) ? (
            <>
              <AddInvoiceModal
                setDisplay={() => setShowAddInvoiceModal(false)}
                show={showAddInvoiceModal}
              />

              <div className="flex space-x-1 px-1">
                {showExportButton && <ExportButton />}
                {showRulesButton && <RulesButton />}

                <Button
                  label="Add Invoices"
                  onClick={() => setShowAddInvoiceModal(true)}
                />
              </div>
            </>
          ) : (
            <></>
          )
        }
      />

      <ScrollMenu>
        <ScrollMenuItem
          text={`Processing (${totals.processing})`}
          selected={pathname.includes(
            `${routes.Spending}${routes.Invoice}${routes.Pending}`,
          )}
          route={`${routes.Spending}${routes.Invoice}${routes.Pending}`}
        />

        <ScrollMenuItem
          text={`Needs Attention (${totals.needsAttention})`}
          selected={pathname.includes(
            `${routes.Spending}${routes.Invoice}${routes.History}`,
          )}
          route={`${routes.Spending}${routes.Invoice}${routes.History}`}
        />

        <ScrollMenuItem
          text={`Approved (${totals.approved})`}
          selected={pathname.includes(
            `${routes.Spending}${routes.Invoice}${routes.Approved}`,
          )}
          route={`${routes.Spending}${routes.Invoice}${routes.Approved}`}
        />

        <ScrollMenuItem
          text={`All (${totals.all})`}
          selected={pathname.includes(
            `${routes.Spending}${routes.Invoice}${routes.All}`,
          )}
          route={`${routes.Spending}${routes.Invoice}${routes.All}`}
        />
      </ScrollMenu>

      <div className="bg-white flex-1 flex flex-col overflow-auto w-full">
        <Outlet />
      </div>
    </>
  )
}
