import { gql } from '@apollo/client'

export const getKitchensQuery = gql`
  query GetKitchens(
    $cursor: KitchenWhereUniqueInput
    $orderBy: [KitchenOrderByInput!]
    $skip: Int
    $take: Int
    $where: KitchenWhereInput
  ) {
    kitchens(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      _cursor
      id
      name
      isNew
    }
  }
`

export const getKitchenNodeQuery = gql`
  query getFullKitchen($cursor: ID!) {
    kitchenNode(cursor: $cursor) {
      _cursor
      id
      name
      joinCode
      supportLink
      onboardState
      isDemoAccount
      menuCount
      recipeCount
      dishCount
      stocktakeCount
      ingredientCount
      orderCount
      salesCount
      priceChangeCount
      invoiceCount
      todoCount
      permissions {
        tier
        trialExpiresAt
      }
      kitchenToCurrentUser {
        roleId
      }
      userPermissions
      setupCompletedAt
    }
  }
`

export const eventUserSelectedKitchenMutation = gql`
  mutation eventUserSelectedKitchen($kitchenId: Int!, $appVersion: String) {
    eventUserSelectedKitchen(kitchenId: $kitchenId, appVersion: $appVersion)
  }
`
