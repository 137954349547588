import { Button, Modal, Pill } from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import useIntegrations, {
  TIntegration,
} from 'screens/Settings/Integrations/useIntegrations'

import { NewLayout, Icon, Typography } from '../../../components/newUi'
import { routes } from '../../../routes/Paths'

export function Integrations() {
  const { selectedKitchen } = useKitchen()
  const { integrations } = useIntegrations(selectedKitchen?.id || 0)
  const navigate = useNavigate()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedIntegration, setSelectedIntegration] =
    useState<TIntegration | null>(null)

  const handleIntegrationClick = (integration: TIntegration) => {
    if (integration.connected) {
      setSelectedIntegration(integration)
      setModalOpen(true)
    } else {
      window.location.href = integration.constructedAuthUrl
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedIntegration(null)
  }

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings)}
        subtitle="Settings"
        title="Integrations"
      />

      <div className="p-4">
        <div>
          {integrations?.length > 0 &&
            integrations.map((integration) => (
              <div
                key={integration.id}
                onClick={() => handleIntegrationClick(integration)}
              >
                <div className="flex cursor-pointer items-center justify-between bg-white p-4 rounded shadow mb-4">
                  <div className="flex items-center">
                    <img
                      src={`/logos/integration-${integration.name.toLowerCase()}-black.png`}
                      alt={integration.name}
                      className="h-16 w-16 object-contain"
                    />
                    <Typography variant="h6" className="ml-4">
                      {integration.name}
                    </Typography>
                  </div>
                  <div>
                    {integration.connected && (
                      <Pill variant="success" label="Connected" />
                    )}
                    <Icon iconName="chevronRight" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        {selectedIntegration && (
          <div className="text-center">
            <div className="space-y-8 w-full">
              <div className="space-y-2">
                <Typography variant="h6" className="text-primary-900">
                  Disconnect integration?
                </Typography>

                <Typography variant="caption" className="text-primary-600">
                  This will completely disconnect {selectedIntegration.name}{' '}
                  integration and will disable publishing, do you want to
                  continue?
                </Typography>
              </div>

              <Button
                style="delete"
                onClick={() => {
                  selectedIntegration.disconnect()
                  handleCloseModal()
                }}
                label="Yes, disconnect"
                className="w-full"
              />

              <Button
                style="secondary"
                onClick={() => {
                  handleCloseModal()
                }}
                label="Cancel"
                className="w-full"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
