import { useMutation } from '@apollo/client'
import { LoginPanel } from '@getjelly/jelly-ui/dist'
import { LoginData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/LoginPanel'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { signInMutation } from './graphql'

import { Mutation, MutationSigninArgs } from '../../api'
import { VERSION } from '../../app'
import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { routes } from '../../routes/Paths'
import { setToken } from '../../store/auth'
import {
  clearAuthFlow,
  selectAuthFlow,
  setCountryCode,
  setPhoneNumber,
} from '../../store/authFlow'
import { selectCurrentVersion } from '../../store/currentVersion'
import { enqueueToast } from '../../store/toastQueue'
import { cleanErrorMessage, telRegex } from '../../utils'

function getNextLocation(skipKitchenSelect: boolean, redirect: string | null) {
  if (!skipKitchenSelect && redirect) {
    return `${routes.Settings + routes.Locations}?redirect=${redirect}`
  }

  if (!skipKitchenSelect && !redirect) {
    return routes.Settings + routes.Locations
  }

  if (skipKitchenSelect && redirect) {
    return redirect
  }

  return routes.Dashboard
}

export function SignIn() {
  const [errors, setErrors] = useState<
    Partial<Record<keyof LoginData, string>> | undefined
  >(undefined)
  const [loginData, setLoginData] = useState<LoginData | undefined>(undefined)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedKitchen } = useKitchen()
  const currentVersion = useSelector(selectCurrentVersion)

  const authFlow = useSelector(selectAuthFlow())

  const { redirect, skipKitchenSelect } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      redirect: searchParams.get('redirect'),
      skipKitchenSelect: searchParams.get('skipKitchenSelect') === 'true',
    }
  }, [location.search])

  const [signin, { loading }] = useMutation<
    { signin: Mutation['signin'] },
    MutationSigninArgs
  >(signInMutation)

  async function attempt({ countryCode, phoneNumber, password }: LoginData) {
    setErrors(undefined)

    if (!telRegex.test(phoneNumber)) {
      return setErrors({ phoneNumber: 'Invalid phone number.' })
    }

    try {
      const { joinCode, inviteCode } = authFlow.invite ?? {}

      const { data } = await signin({
        variables: {
          appVersion: currentVersion || VERSION,
          countryCode: countryCode.trim(),
          inviteCode,
          joinCode,
          password: password.trim(),
          phoneNumber: phoneNumber.trim(),
        },
      })

      const joinError = data?.signin?.joinError
      if (joinError) {
        dispatch(enqueueToast({ message: joinError, type: 'error' }))
      }

      const route = authFlow.invite
        ? routes.Settings + routes.Locations
        : getNextLocation(!!selectedKitchen || skipKitchenSelect, redirect)

      dispatch(setToken(data?.signin?.token ?? null))
      dispatch(clearAuthFlow())

      return navigate(route)
    } catch (error) {
      console.error(error)

      setErrors({
        password:
          error instanceof Error
            ? cleanErrorMessage(error.message)
            : 'Unknown error occurred.',
      })

      return
    }
  }

  return (
    <LoginPanel
      countryCode={authFlow.countryCode}
      phoneNumber={authFlow.phoneNumber}
      onChange={setLoginData}
      login={attempt}
      loading={loading}
      errors={errors}
      forgotPasswordLinkClicked={() => navigate(routes.ForgotPassword)}
      registerLinkClicked={() => {
        dispatch(setCountryCode(loginData?.countryCode))
        dispatch(setPhoneNumber(loginData?.phoneNumber))
        navigate(routes.Welcome)
      }}
    />
  )
}
