import { Button, Modal, Typography, NumberInput } from '@getjelly/jelly-ui'
import { IconCurrencyPound } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

export type CurrentSales = {
  date: Date
  sales: number | undefined
}

type Props = {
  currentSales: CurrentSales
  open: boolean
  onClose: () => void
  saveSales: (date: Date, sales: number | undefined) => Promise<void>
}

export function UpdateSalesModal({
  open,
  onClose,
  currentSales,
  saveSales,
}: Props) {
  const [sales, setSales] = useState(
    currentSales.sales === undefined ? '' : currentSales.sales.toString(),
  )

  const [salesValue, setSalesValue] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(
    () =>
      setSales(
        currentSales.sales === undefined ? '' : currentSales.sales.toString(),
      ),
    [currentSales],
  )

  useEffect(() => {
    if (sales.trim() === '') {
      return setSalesValue(undefined)
    }

    const parsed = parseFloat(sales)

    if (!Number.isNaN(parsed)) {
      setSalesValue(parsed)
    }
  }, [sales])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-6">
        <div className="space-y-2 text-center">
          <Typography style="h6" className="text-primary-900">
            Add sales
          </Typography>

          <Typography style="caption" className="text-primary-600">
            Enter net sales figure for the day, excluding VAT
          </Typography>
        </div>

        <div className="space-y-2">
          <Typography style="caption" className="text-primary-800">
            Sales
          </Typography>

          <NumberInput
            value={sales}
            onChange={setSales}
            leftIcon={IconCurrencyPound}
            placeholder="Enter net sales"
          />
        </div>

        <Button
          onClick={async () => {
            setLoading(true)
            try {
              await saveSales(currentSales.date, salesValue)
            } finally {
              setLoading(false)
            }
          }}
          label="Save"
          loading={loading}
          className="w-full"
        />
      </div>
    </Modal>
  )
}
