import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Query, ProductWhereInput, useGetSupplierQuery } from 'api'
import { useIsDesktop } from 'app/contexts/IsDesktop'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  NewLayout,
  NewButton,
  Icon,
  VirtualizedSectionList,
  LeaveConfirmationModal,
} from 'components/newUi'
import { Portal } from 'components/newUi/NewLayout'
import { deleteOrder, selectProducts } from 'screens/Order/store'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

import { Comments } from './Comments'
import { Confirm } from './Confirm'

import { getBasketProductsQuery } from '../graphql'
import { Item } from '../Products/components/Item'

export const Basket = () => {
  const isDesktop = useIsDesktop()
  const { theme } = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedKitchen } = useKitchen(true)
  const { id } = useParams()
  const products = useSelector(selectProducts(`${selectedKitchen?.id}:${id}`))

  const { data, loading } = useGetSupplierQuery({
    skip: !selectedKitchen?.id || !id,
    variables: {
      kitchenId: selectedKitchen?.id ?? -1,
      supplierId: Number(id) ?? -1,
    },
  })

  const [showClearModal, setShowClearModal] = useState(false)

  const productIds = Object.keys(products || {})
  const { data: productData, loading: productLoading } = useQuery<
    {
      products: Query['products']
    },
    {
      where: ProductWhereInput
      kitchenId: number
    }
  >(getBasketProductsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !selectedKitchen?.id || !id || !productIds.length,
    variables: {
      kitchenId: selectedKitchen?.id ?? -1,
      where: {
        OR: productIds.map((id) => ({ id: { equals: parseInt(id) } })),
      },
    },
  })

  const kitchenProducts = productData?.products.filter(
    (p) => p.ingredients.length,
  )
  const catalogProducts = productData?.products.filter(
    (p) => !p.ingredients.length,
  )
  const groupedProducts = []
  if (kitchenProducts?.length)
    groupedProducts.push({
      items: kitchenProducts,
    })
  if (catalogProducts?.length)
    groupedProducts.push({
      itemStyle: {
        background: theme.palette.success[25].toHexString(),
      },
      items: catalogProducts,
      label: 'New products from catalogue',
    })

  const costEstimate = products
    ? Object.keys(products).reduce((total, key) => {
        const productInStore = products[key]
        const productFromApi = productData?.products.find(
          (p) => p.id === parseInt(key),
        )
        if (
          !productFromApi ||
          productInStore?.sizeType === 1 ||
          productInStore?.sizeType === 2
        ) {
          return total
        }
        if (productFromApi?.ingredients.length)
          return (
            total +
            (productFromApi?.ingredients[0]?.price ?? 0) *
              (productInStore?.quantity ?? 0)
          )
        return -9999
      }, 0)
    : 0

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <LeaveConfirmationModal
        action="clear"
        block={false}
        title="Are you sure you want to clear your basket?"
        text=""
        onConfirm={() => {
          setShowClearModal(false)

          dispatch(deleteOrder(`${selectedKitchen?.id}:${id}`))
          navigate(-1)
        }}
        onCancel={() => {
          setShowClearModal(false)
        }}
        show={showClearModal}
      />
      <NewLayout
        hideMenu
        title="Basket"
        subtitle={
          data?.supplier?.name ? capitaliseEachWord(data?.supplier?.name) : ''
        }
        rightContent={
          <NewButton
            text="Clear"
            color="orange"
            noFill
            rightAdornment={<Icon iconName="close" />}
            style={{ padding: '8px 10px 8px 10px' }}
            onClick={() => setShowClearModal(true)}
          />
        }
      />
      <Portal id="react-bottom-portal">
        <Comments fetching={loading} />
        <div
          style={{
            backgroundColor: theme.palette.secondary[100].toHexString(),
            borderTopColor: theme.palette.primary[10].toHexString(),
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            display: 'flex',
            justifyContent: 'center',
            padding: 15,
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography style={{ color: 'white' }} variant="button">
              Est. Total £
              {Math.sign(costEstimate) === -1
                ? '??'
                : costEstimate.toLocaleString('en', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
            </Typography>
            <Confirm id={id as string} selectedKitchen={selectedKitchen} />
          </div>
        </div>
      </Portal>
      <div style={{ height: `100%` }}>
        <div style={{ height: '100%', width: '100%' }}>
          <VirtualizedSectionList
            emptyMessage={
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing(1),
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.palette.primary[60].toHexString(),
                  }}
                >
                  Your basket is empty
                </Typography>
              </div>
            }
            ROW_HEIGHT={isDesktop ? 65 : 92}
            Item={({ index, data, style }) => (
              <Item
                style={style}
                includeCost
                rowData={data.items[index]}
                supplierId={id as string}
                selectedKitchen={selectedKitchen}
              />
            )}
            // @ts-ignore
            data={groupedProducts}
            loading={productLoading}
          />
        </div>
      </div>
    </div>
  )
}
