import { gql } from '@apollo/client'

export const dashboardKitchenQuery = gql`
  query dashboardKitchenQuery($period: PeriodEnum!, $kitchenId: Int!) {
    dashboardKitchen(period: $period, kitchenId: $kitchenId) {
      previousStockValue
      recentOrder {
        supplierName
        date
        creatorName
      }
      topMenuDiff {
        target
        name
        diff
      }
      topStockTake {
        value
        name
        date
      }
      totalCookbookUpdates
      totalOrdersPlaced
      totalStockValue
    }
  }
`
