import { Button } from '@getjelly/jelly-ui'
import { isEmpty } from 'ramda'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  NewLayout,
  Icon,
  ScrollMenu,
  ScrollMenuItem,
  AlertWithIcon,
  Typography,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { selectAllPendingOrdersForKitchen } from 'screens/Order/store'
import { useTheme } from 'styles/newUi'

export const OrderingLayout = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const { selectedKitchen } = useKitchen(true)
  const [orderPlaced, setOrderPlaced] = useState<any>()

  useEffect(() => {
    // @ts-ignore
    if (state?.orderPlaced) {
      // @ts-ignore
      setOrderPlaced(state?.orderPlaced)
      navigate(`${routes.Order}${routes.History}`, {
        replace: true,
        state: {},
      })
    }
  }, [])

  const orders = useSelector(
    selectAllPendingOrdersForKitchen(`${selectedKitchen?.id || ''}`),
  )

  const activeOrdersCount = Object.keys(orders).reduce((acc: any, cur: any) => {
    if (orders[cur].quantity > 0 || !isEmpty(orders[cur].comments)) {
      return (acc += 1)
    }
    return acc
  }, 0)
  return (
    <>
      <NewLayout
        title="Order"
        onBack={() => navigate(-1)}
        rightContent={
          selectedKitchen?.userPermissions?.includes(
            'create-purchase-order',
          ) ? (
            <div className="px-2">
              <Button
                onClick={() => navigate(`${routes.Order}${routes.Supplier}`)}
                label="Place Order"
              />
            </div>
          ) : undefined
        }
      />
      {orderPlaced && (
        <AlertWithIcon hide={false} type="success" iconName="notifications">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.success[100].toHexString() }}
            >
              Your order to {orderPlaced?.supplierName} was placed!
            </Typography>
          </div>
        </AlertWithIcon>
      )}
      {activeOrdersCount ? (
        <AlertWithIcon
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`${routes.Order}${routes.Supplier}`)}
          hide={false}
          type="info"
          iconName="info"
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2">
              You have {activeOrdersCount} active shopping basket
              {activeOrdersCount > 1 ? 's' : ''}
            </Typography>
            <Icon iconName="chevronRight" />
          </div>
        </AlertWithIcon>
      ) : undefined}
      <ScrollMenu>
        <ScrollMenuItem
          text="Your Orders"
          selected={pathname.includes(`${routes.Order}${routes.History}`)}
          route={`${routes.Order}${routes.History}`}
        />
      </ScrollMenu>
      <Outlet />
    </>
  )
}
