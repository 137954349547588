import dayjs from 'dayjs'
import { isEmpty } from 'ramda'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useFuzzySearchProductListQuery, useGetSupplierQuery } from 'api'
import { useIsDesktop } from 'app/contexts/IsDesktop'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  Button,
  CloseIcon,
  Grid,
  Loader,
  NewTextField,
  SearchIcon,
} from 'components'
import { AlertWithIcon, Icon, NewLayout, Typography } from 'components/newUi'
import { Portal } from 'components/newUi/NewLayout'
import { VirtualizedFlatList } from 'components/newUi/VirtualizedFlatList'
import { useDebounce } from 'hooks'
import { routes } from 'routes/Paths'
import { processFlatData } from 'screens/Order/Products/processFlatData'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, encodeCursor } from 'utils'

import { Confirm } from './components/Confirm'
import { Item } from './components/Item'
import { PendingOrder } from './components/PendingOrder'

import { Comments } from '../Supplier/Comments'

export const OrderSupplierProducts = () => {
  const isDesktop = useIsDesktop()
  const { selectedKitchen } = useKitchen(true)
  const { theme } = useTheme()
  const navigate = useNavigate()
  const { id } = useParams()
  const listRef = useRef()
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)
  const { data: supplierData, loading: supplierLoading } = useGetSupplierQuery({
    skip: !selectedKitchen?.id || !id,
    variables: {
      kitchenId: selectedKitchen?.id ?? -1,
      supplierId: Number(id) ?? -1,
    },
  })

  const {
    data: productData,
    loading: productLoading,
    fetchMore,
  } = useFuzzySearchProductListQuery({
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen?.id || !id,
    variables: {
      kitchenCursor: encodeCursor('Kitchen', selectedKitchen?.id as number),
      kitchenId: selectedKitchen?.id ?? -1,
      searchTerm: debouncedSearch,
      skip: 0,
      supplierCursor: encodeCursor('Supplier', parseInt(id as string)),
      take: 50,
    },
  })

  const groupedProducts = useMemo(() => {
    if (!productData) return []

    return processFlatData({
      catalogueCount: productData?.fuzzySearchProductList.catalogueCount,
      data: productData?.fuzzySearchProductList.nodes,
      kitchenCount: productData?.fuzzySearchProductList.kitchenCount,
      recentCount: productData?.fuzzySearchProductList.recentCount,
      theme,
    })
  }, [productData, theme])

  useEffect(() => {
    // @ts-ignore
    listRef.current?.recomputeRowHeights()
  }, [groupedProducts])

  const today = dayjs()
  const cutoff =
    supplierData?.supplier?.deliveryDays &&
    supplierData?.supplier?.deliveryDays[today.format('ddd').toLowerCase()]
      ?.cutoff
  const cutoffDay =
    cutoff &&
    dayjs()
      .hour(parseInt(cutoff.slice(0, 2)))
      .minute(parseInt(cutoff.slice(3, 5)))
  const cutoffMinus2 =
    cutoff &&
    dayjs()
      .hour(parseInt(cutoff.slice(0, 2)))
      .minute(parseInt(cutoff.slice(3, 5)))
      .subtract(2, 'hours')
  const afterCutOff =
    cutoff && today.isAfter(cutoffMinus2) && today.isBefore(cutoffDay)

  const cutoff2 =
    supplierData?.supplier?.deliveryDays &&
    supplierData?.supplier?.deliveryDays[today.format('ddd').toLowerCase()]
      ?.cutoff2
  const cutoff2Day =
    cutoff2 &&
    dayjs()
      .hour(parseInt(cutoff2.slice(0, 2)))
      .minute(parseInt(cutoff2.slice(3, 5)))
  const cutoff2Minus2 =
    cutoff2 &&
    dayjs()
      .hour(cutoff2.slice(0, 2))
      .minute(cutoff2.slice(3, 5))
      .subtract(2, 'hours')
  const afterCutOff2 =
    cutoff2 && today.isAfter(cutoff2Minus2) && today.isBefore(cutoff2Day)

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <PendingOrder
        supplierData={supplierData}
        supplierLoading={supplierLoading}
        id={id as string}
        selectedKitchen={selectedKitchen}
      />
      <NewLayout
        hideMenu
        title={
          supplierData?.supplier?.name
            ? capitaliseEachWord(supplierData?.supplier?.name)
            : ''
        }
        subtitle="Suppliers"
        rightContent={
          <Button
            color="secondary"
            style={{
              color: theme.palette.secondary[100],
              fontWeight: 'bold',
            }}
            onClick={() => {
              navigate(
                `${routes.Order}${routes.Supplier}${routes.View}/${supplierData?.supplier?._cursor}`,
              )
            }}
          >
            Info
            <Icon iconName="info" style={{ height: 20 }} />
          </Button>
        }
      />
      <Portal id="react-bottom-portal">
        <Comments fetching={supplierLoading} />
        <div
          style={{
            backgroundColor: theme.palette.secondary[100].toHexString(),
            borderTopColor: theme.palette.primary[10].toHexString(),
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 16,
            paddingTop: 17,
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.secondary[100].toHexString(),
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Confirm id={id as string} selectedKitchen={selectedKitchen} />
          </div>
        </div>
      </Portal>
      {cutoff && afterCutOff && !afterCutOff2 && (
        <AlertWithIcon hide={false} type="error" iconName="warning">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.error[100].toHexString() }}
            >
              Order by {cutoff} for delivery today
            </Typography>
          </div>
        </AlertWithIcon>
      )}

      {afterCutOff2 && (
        <AlertWithIcon hide={false} type="error" iconName="warning">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.error[100].toHexString() }}
            >
              Order by {cutoff2} for delivery tomorrow
            </Typography>
          </div>
        </AlertWithIcon>
      )}
      <NewTextField
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          flex: 1,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
        inputProps={{
          'data-hj-allow': '',
          id: 'searchInput',
          style: { borderRadius: 25, flex: 1 },
        }}
        autoComplete={false}
        placeholder="Search by product name or code"
        value={search}
        onChange={(value) => setSearch(value as unknown as string)}
        endAdornment={
          isEmpty(search) ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => setSearch('')} />
          )
        }
      />

      <div
        style={{
          height: `calc(100% - ${
            (cutoff && afterCutOff && !afterCutOff2) || afterCutOff2 ? 130 : 70
          }px)`,
        }}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <VirtualizedFlatList
            ROW_HEIGHT={isDesktop ? 65 : 92}
            ref={listRef}
            Item={({ data, style }) => (
              <Item
                style={style}
                supplierId={id}
                selectedKitchen={selectedKitchen}
                rowData={data}
                search={debouncedSearch}
              />
            )}
            data={groupedProducts}
            loading={productLoading}
            pageInfo={{
              hasNextPage:
                productData?.fuzzySearchProductList?.pageInfo?.hasNextPage ??
                false,
              hasPreviousPage: false,
              skip: productData?.fuzzySearchProductList.nodes?.length ?? 0,
              take: 48,
            }}
            fetchMore={() => {
              fetchMore({
                variables: {
                  skip: productData?.fuzzySearchProductList.nodes.length,
                  take: 48,
                },
              })
            }}
            footer={
              <>
                {productData?.fuzzySearchProductList?.pageInfo?.hasNextPage &&
                  productLoading &&
                  groupedProducts.length > 0 && (
                    <div
                      style={{
                        marginBottom: 5,
                        marginTop: 5,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                {!isEmpty(debouncedSearch) &&
                  !productData?.fuzzySearchProductList?.pageInfo
                    ?.hasNextPage && (
                    <Grid
                      onClick={() => {
                        //hack
                        document.getElementById('commentsInput')?.focus()
                      }}
                      container
                      item
                      xs={12}
                      style={{
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[5].toHexString(),
                        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 100,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                        }}
                      >
                        Cant&apos;t find a product?
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          color: theme.palette.secondary[100].toHexString(),
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                        }}
                      >
                        Add a message to{' '}
                        {capitaliseEachWord(
                          supplierData?.supplier?.name.toLowerCase(),
                        )}
                      </Typography>
                    </Grid>
                  )}
                {isEmpty(debouncedSearch) &&
                  (groupedProducts.length === 0 ||
                    !productData?.fuzzySearchProductList?.pageInfo
                      ?.hasNextPage) && (
                    <Grid
                      onClick={() => {
                        //hack
                        document.getElementById('searchInput')?.focus()
                      }}
                      container
                      item
                      xs={12}
                      style={{
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[5].toHexString(),
                        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 100,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                          paddingLeft: theme.spacing(2),
                          paddingRight: theme.spacing(2),
                          textAlign: 'center',
                        }}
                      >
                        There are no more products in your kitchen from this
                        supplier
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          color: theme.palette.secondary[100].toHexString(),
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                        }}
                      >
                        Search the catalogue{' '}
                        <Icon
                          style={{ height: 21, marginLeft: 3, width: 21 }}
                          iconName="search"
                        />
                      </Typography>
                    </Grid>
                  )}
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}
