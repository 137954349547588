import { useMutation, useQuery } from '@apollo/client'
import { Button, TextInput, Typography } from '@getjelly/jelly-ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Mutation, MutationUpdateOneUserArgs, Query } from 'api'
import {
  AlertWithIcon,
  NewLayout,
  ProfilePicture,
  LeaveConfirmationModal,
} from 'components/newUi'
import { cleanErrorMessage } from 'utils'

import { updateOneUserMutation } from './graphql'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { useS3Upload } from '../../Create/ImageContainer/hooks/useS3Upload'
import { getMeQuery } from '../graphql'

const validationSchema = Yup.object({
  countryCode: Yup.string(),
  email: Yup.string().email().required('Required'),
  firstName: Yup.string(),
  lastName: Yup.string(),
  phoneNumberNational: Yup.string(),
})

export function MeEdit() {
  const { selectedKitchen } = useKitchen(true)
  const { data } = useQuery<{ me: Query['me'] }>(getMeQuery)

  const [updateOneUser, { error, loading: updatingUser, data: successData }] =
    useMutation<
      { updateOneUser: Mutation['updateOneUser'] },
      MutationUpdateOneUserArgs
    >(updateOneUserMutation)

  const user = data?.me

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryCode: user?.countryCode ?? '',
      email: user?.email ?? '',
      firstName: user?.firstName ?? '',
      imageUrl: user?.imageUrl ?? '',
      lastName: user?.lastName ?? '',
      phoneNumberNational: user?.phoneNumberNational ?? '',
    },
    async onSubmit(variables) {
      if (!user || !user.id) {
        return
      }

      try {
        await updateOneUser({
          variables: {
            data: {
              email:
                user.email === variables.email
                  ? undefined
                  : variables.email.trim(),
              id: user.id,
              imageUrl:
                user.imageUrl === variables.imageUrl
                  ? undefined
                  : variables.imageUrl,
            },
          },
        })
      } catch (e) {
        if (e instanceof Error) cleanErrorMessage(e.message)
      }
    },
    validationSchema,
  })

  const { onChange, loading: uploadingPicture } = useS3Upload({
    getFileName(file) {
      if (!user) {
        return `ProfilePicture/unknown/${file.name}`
      }

      return `ProfilePicture/${user.id}/${file.name}`
    },
    kitchenId: selectedKitchen?.id,
    resizeConfig: { maxHeight: 512, maxWidth: 512, quality: 0.8 },
    setImageUrl(s3Url) {
      formik.setFieldValue('imageUrl', s3Url)
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <NewLayout
        title="My Profile"
        subtitle="Settings"
        bottomBackgroundColor="white"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              disabled={!formik.dirty}
              loading={updatingUser}
              onClick={() => formik.handleSubmit()}
              label="Update Details"
              className="w-full"
            />
          </div>
        }
      />

      <LeaveConfirmationModal
        action="YES, LEAVE"
        title="Leave this page without saving?"
        cancelLabel="NO, STAY"
        text=""
        onConfirm={(state) => state?.retry()}
        show={formik.dirty}
      />

      <AlertWithIcon hide={!error} type="error" iconName="warning">
        <Typography style="body1">
          {error?.message?.includes('Unique constraint')
            ? 'Email is already in use with another account, please use a different email address'
            : cleanErrorMessage(error?.message)}
        </Typography>
      </AlertWithIcon>

      <AlertWithIcon
        hide={!successData}
        type="success"
        iconName="notifications"
      >
        <Typography style="body1">Your profile was updated</Typography>
      </AlertWithIcon>

      <div className="p-3">
        <div className="flex flex-col items-center">
          <ProfilePicture
            loading={uploadingPicture}
            imageUrl={formik.values.imageUrl}
            size="large"
            onClick={(e) => {
              e.preventDefault()
              document.getElementById('file')?.click()
            }}
          />

          <Button
            style="ghost"
            label="Replace Photo"
            className="!bg-transparent !border-transparent"
            onClick={(e) => {
              e.preventDefault()
              document.getElementById('file')?.click()
            }}
          />

          <input
            onChange={(e) => onChange(e.target.files)}
            id="file"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
          />
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Your name
            </Typography>

            <TextInput
              value={`${formik.values.firstName} ${formik.values.lastName}`}
              error={formik.errors.firstName || formik.errors.lastName}
              onChange={() => void 0}
              disabled
            />
          </div>

          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Your phone number
            </Typography>

            <TextInput
              value={`+${formik.values.countryCode} ${formik.values.phoneNumberNational}`}
              error={
                formik.errors.countryCode || formik.errors.phoneNumberNational
              }
              onChange={() => void 0}
              disabled
            />
          </div>

          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Your email
            </Typography>

            <TextInput
              value={formik.values.email}
              error={formik.errors.email}
              onChange={(v) => formik.setFieldValue('email', v)}
            />
          </div>
        </div>
      </div>
    </form>
  )
}
