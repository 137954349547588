import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

import { Loader } from '../components'
import { useMe } from '../hooks/auth'
import { selectToken } from '../store/auth'

export function Routes() {
  const navigate = useNavigate()
  const location = useLocation()
  const token = useSelector(selectToken())
  const { refetch, loading, onboardRoute } = useMe()

  useEffect(() => void refetch(), [token])

  useEffect(() => {
    if (onboardRoute && location.pathname !== onboardRoute) {
      navigate(onboardRoute)
    }
  }, [location, onboardRoute])

  if (loading) {
    return (
      <div className="h-full bg-primary-900 flex items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (!token) {
    return <PublicRoutes />
  }

  return <PrivateRoutes />
}
