import { isBefore } from 'date-fns'
import { useMemo } from 'react'

import { useKitchen } from '../app/contexts/SelectedKitchen'
import { errorToast } from '../components/toasts'

export function useIsPaidKitchen() {
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen(true)

  refetchSelectedKitchen().catch(() =>
    errorToast('Failed to refetch selected kitchen.'),
  )

  return useMemo(() => {
    if (!selectedKitchen?.permissions?.tier) {
      return false
    }

    if (
      selectedKitchen.permissions.trialExpiresAt &&
      isBefore(selectedKitchen.permissions.trialExpiresAt, new Date())
    ) {
      return true
    }

    return ['PLUS', 'PRO'].includes(selectedKitchen.permissions.tier)
  }, [selectedKitchen])
}
