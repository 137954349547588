import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { HomeOnboarding } from './HomeOnboarding'
import { HomeTodo } from './HomeTodo'

import { useKitchen } from '../../app/contexts/SelectedKitchen'

export function Home() {
  const location = useLocation()
  const { selectedKitchen } = useKitchen(true)

  const onboardingComplete = useMemo(
    () => (selectedKitchen ? !!selectedKitchen.setupCompletedAt : false),
    [location.pathname],
  )

  if (!selectedKitchen) {
    return <></>
  }

  if (onboardingComplete) {
    return <HomeTodo kitchen={selectedKitchen} />
  }

  return <HomeOnboarding kitchen={selectedKitchen} />
}
